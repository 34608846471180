import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getTasks,
  getTasksResolved,
  getTask,
  getTaskResolved,
  addTask,
  addTaskResolved,
  deleteTask,
  deleteTaskResolved,
  updateTask,
  updateTaskResolved,
  getAllTasks,
  getAllTasksResolved,
  createTaskDocuments,
  createTaskDocumentsResolved,
  addTaskLogs,
  addTaskLogsResolved,
  removeDocuments,
  removeDocumentsResolved as removeDocumentsResolved,
  linkSubtasks,
  linkSubtasksResolved,
  updateTaskStatus,
  addStatuses,
  addStatusesResolved,
  updateStatuses,
  updateStatusesResolved,
  getKanban,
  getKanbanResolved,
  getTaskDocuments,
  getTaskDocumentsResolved,
  getSubTasks,
  getSubTasksResolved,
  deleteStatus,
  deleteStatusResolved,
  getStatuses,
  getStatusesResolved,
} from './task.actions';
import { TaskApiService } from '../../services/task-api.service';
import { TaskDocumentsApiService } from '../../services/task-documents-api.service';
import { StatusApiService } from '../../services/status-api.service';
import { LookupTypesApiService } from '../../services/lookup-types-api.service';

@Injectable({ providedIn: 'root' })
export class TaskEffects {
  filter = 'IsDeleted eq false';
  constructor(
    private actions$: Actions,
    private statusApiService: StatusApiService,
    private taskTypeApiService: LookupTypesApiService,
    private taskDocumentsApiService: TaskDocumentsApiService,
    private taskApiService: TaskApiService
  ) {}

  getTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(getTasks),
      mergeMap(
        ({
          newPageSize,
          sortedColumn,
          sortDirection,
          filters,
          assignedToMe,
          newPageNumber,
          initialLoading,
          resetCache,
          callback,
          errorCallback,
        }) =>
          this.taskApiService
            .getTasks(
              newPageSize,
              sortedColumn,
              sortDirection,
              assignedToMe,
              filters,
              newPageNumber,
              initialLoading,
              resetCache
            )
            .pipe(
              switchMap(
                ({ result, pageNumber, assignedToMe, totalRecordCount }) =>
                  of(
                    getTasksResolved({
                      result,
                      pageNumber,
                      totalRecordCount,
                      assignedToMe,
                      resetPaging: !newPageNumber,
                    })
                  )
              ),
                tap((response) => (callback ? callback(response.result) : undefined)),
              catchError((e) => {
                errorCallback?.(e);
                return [];
              })
            )
      )
    )
  );

  getAllTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllTasks),
      switchMap(({ callback, errorCallback }) =>
        this.taskApiService.getAllTasks().pipe(
          tap(() => (callback ? callback() : undefined)),
          switchMap((data) => of(getAllTasksResolved({ tasks: data }))),
          catchError((e) => {
            errorCallback?.(e);
            return [];
          })
        )
      )
    )
  );

  getTask = createEffect(() =>
    this.actions$.pipe(
      ofType(getTask),
      mergeMap(({ ishtarTaskId, errorCallback, callback }) =>
        this.taskApiService.getTask(ishtarTaskId).pipe(
          tap((task) => (callback ? callback({ task }) : undefined)),
          switchMap((task) => of(getTaskResolved({ task }))),
          catchError((e) => {
            errorCallback?.(e);
            return [];
          })
        )
      )
    )
  );

  addTask = createEffect(() =>
    this.actions$.pipe(
      ofType(addTask),
      mergeMap(({ task, callback, errorCallback }) =>
        this.taskApiService.addTask(task).pipe(
          tap((task) => (callback ? callback(task) : undefined)),
          switchMap((addedTask) => of(addTaskResolved({ addedTask }))),
          catchError((e) => {
            errorCallback?.(e);
            return [];
          })
        )
      )
    )
  );

  // addSubtask = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(addSubtask),
  //     mergeMap(({ task }) =>
  //       this.ishtarFunctionService.addTask(task).pipe(
  //         switchMap((addedTask) => of(addTaskResolved({ addedTask }))),
  //         catchError((e) => [])
  //       )
  //     )
  //   )
  // );

  updateTask = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTask),
      mergeMap(({ task, callback, error }) =>
        this.taskApiService.updateTask(task).pipe(
          tap((task) => (callback ? callback(task) : undefined)),
          switchMap((task) => {
            return of(updateTaskResolved(task));
          }),
          catchError((e) => {
            error?.(e);
            return [];
          })
        )
      )
    )
  );

  updateTaskStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTaskStatus),
      mergeMap(({ taskId, statusId, callback, error }) =>
        this.taskApiService.updateTaskStatus(taskId, statusId).pipe(
          tap((tasks) => (callback ? callback({ tasks }) : undefined)),
          switchMap((tasks) => {
            return of(updateTaskResolved(tasks));
          }),
          catchError((e) => {
            error?.(e);
            return [];
          })
        )
      )
    )
  );

  removeTask = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTask),
      mergeMap(({ ishtarTaskId, callback, errorCallback }) =>
        this.taskApiService.removeTask(ishtarTaskId).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          mergeMap((data) => of(deleteTaskResolved({ ishtarTaskId: data }))),
          catchError((e) => {
            if (errorCallback) errorCallback(e);
            return [];
          })
        )
      )
    )
  );

  addTaskLogs = createEffect(() =>
    this.actions$.pipe(
      ofType(addTaskLogs),
      mergeMap(({ logs, callback, error }) =>
        this.taskApiService.addTaskLogs(logs).pipe(
          tap((logs) => (callback ? callback(logs) : undefined)),
          switchMap((logs) => of(addTaskLogsResolved({ logs }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getTaskDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(getTaskDocuments),
      mergeMap(({ taskId, callback, errorCallback }) =>
        this.taskDocumentsApiService.getTaskDocuments(taskId).pipe(
          tap((taskDocuments) =>
            callback ? callback(taskDocuments) : undefined
          ),
          switchMap((taskDocuments) =>
            of(getTaskDocumentsResolved({ taskDocuments }))
          ),
          catchError((e) => {
            errorCallback?.(e);
            return [];
          })
        )
      )
    )
  );

  createTaskDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(createTaskDocuments),
      mergeMap(({ taskDocumentRequests, callback, error }) =>
        this.taskDocumentsApiService
          .createTaskDocuments(taskDocumentRequests)
          .pipe(
            tap((logs) => (callback ? callback(logs) : undefined)),
            switchMap((contract) =>
              of(
                createTaskDocumentsResolved({
                  contract: contract,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  removeTaskDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDocuments),
      mergeMap(({ taskId, dmsAppItemIds, callback, error }) =>
        this.taskDocumentsApiService.removeTaskDocuments(dmsAppItemIds).pipe(
          tap((logs) => (callback ? callback(logs) : undefined)),
          switchMap((ids) =>
            of(
              removeDocumentsResolved({
                taskId,
                dmsAppItemIds: ids,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getSubTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubTasks),
      mergeMap(({ parentTaskId, callback, errorCallback }) =>
        this.taskApiService.getSubTasks(parentTaskId).pipe(
          tap((subTasks) => (callback ? callback(subTasks) : undefined)),
          switchMap((subTasks) => of(getSubTasksResolved({ subTasks }))),
          catchError((e) => {
            errorCallback?.(e);
            return [];
          })
        )
      )
    )
  );

  linkSubtasks = createEffect(() =>
    this.actions$.pipe(
      ofType(linkSubtasks),
      mergeMap(({ taskId, subtaskIds, callback }) =>
        this.taskApiService.linkSubtasks(taskId, subtaskIds).pipe(
          tap((tasks) =>
            callback ? callback({ subtasks: tasks }) : undefined
          ),
          switchMap((tasks) => of(linkSubtasksResolved({ subtasks: tasks }))),
          catchError((e) => [])
        )
      )
    )
  );

  addStatuses = createEffect(() =>
    this.actions$.pipe(
      ofType(addStatuses),
      switchMap(({ statuses, callback, error }) =>
        this.statusApiService.addStatuses(statuses).pipe(
          tap((statuses) => (callback ? callback(statuses) : undefined)),
          switchMap((addedStatuses) =>
            of(addStatusesResolved({ addedStatuses }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getStatuses = createEffect(() =>
    this.actions$.pipe(
      ofType(getStatuses),
      switchMap(({ callback, error }) =>
        this.taskTypeApiService.getStatusTypes().pipe(
          tap((statuses) => (callback ? callback(statuses) : undefined)),
          switchMap((statuses) => of(getStatusesResolved({ statuses }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateStatuses = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStatuses),
      switchMap(({ statuses, callback, error }) =>
        this.statusApiService.updateStatuses(statuses).pipe(
          tap((statuses) => (callback ? callback(statuses) : undefined)),
          switchMap((updatedStatuses) =>
            of(updateStatusesResolved({ updatedStatuses }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteStatus),
      switchMap(({ ishtarStatusId, callback, error }) =>
        this.statusApiService.removeStatus(ishtarStatusId).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((data) => of(deleteStatusResolved({ ishtarStatusId: data }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getkanban = createEffect(() =>
    this.actions$.pipe(
      ofType(getKanban),
      switchMap(({ assignedToMe, callback, error }) =>
        this.taskApiService.getKanban(assignedToMe).pipe(
          tap((x) => (callback ? callback({ tasks: x }) : undefined)),
          switchMap((tasks) => of(getKanbanResolved({ tasks }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
