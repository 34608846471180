import { Lookup } from "../lookup";
import { Action } from "../task/decision/action";
import { ExtraFieldSetting } from "../task/field/extraFieldSetting";
import { Appointment } from "./appointment";
import { DMSItem } from "../dms/dms-item.model";
import { Status } from "./status";
import { TaskDependency } from "./taskDependency";
import { TaskFollower } from "./taskFollower";
import { TaskLog } from "./taskLog";
import { CheckListItem } from "./checklistItem";
import { ApprovalFlow } from "./decision/approvalflow";
import { DependencyValue } from "./dependencyValue";
import { ApplicationNotificationDto, GroupUser } from "processdelight-angular-components";
import { ResourceFunction } from "../resource/resource.model";

export class Task {
    ishtarTaskId!: string;
    taskName!: string;
    taskId?: string;
    number?: string;
    title!: string;
    description?: string;
    createdBy?: { id?: string; name?: string };
    createdOn?: string;
    startTime?: string;
    endTime?: string;
    deadline?: string;
    progress?: number;
    progressRegistrationType?: { id?: string; name?: string };
    parentTask?: Lookup;
    subTasks?: Task[];
    project?: { id?: string; name?: string };
    status?: { id: string; name?: string };
    priority?: { id?: string; name?: string };
    skill?: { id?: string; name?: string };
    type?: { id?: string; name?: string };
    isTaskTemplate?: boolean;
    closeTaskWhenSubtasksDone?: boolean;
    closeParentTask?: boolean;
    decision?: { id?: string; name?: string };
    appointments?: Appointment[];
    logs?: TaskLog[];
    taskDocuments?: DMSItem[];
    actions?: Action[];
    taskFollowers?: TaskFollower[];
    checkList?: CheckListItem[];
    extraFieldSettings?: ExtraFieldSetting[];
    dependencies?: TaskDependency[] = [];
    dependencyValues?: DependencyValue[] = [];
    notifications?: ApplicationNotificationDto[];
    estimatedTime?: number;
    completedTime?: number;
    isDeleted?: boolean = false;
    owners?: GroupUser[];
    followers?: GroupUser[];
    approvalFlows?: ApprovalFlow[];
    approvalType?: Lookup;
    approvalLink?: Lookup;
    resourceUsers?: GroupUser[];
    resourceFunctions?: ResourceFunction[];
    createdByUser?: GroupUser;

    isLoadedFromTemplate = false;
    taskTemplateSourceId?: string;

    constructor(obj: Partial<Task>) {
        delete (obj as any).id;
        delete (obj as any).name;
        Object.assign(this, obj);
        this.owners = obj.owners?.map((e) => new GroupUser(e)) || [];
        this.approvalFlows =
            obj.approvalFlows?.map((e) => new ApprovalFlow(e)) || [];
    }
}

export class TaskContract {
    id!: string;
    name!: string;
    number?: string;
    status?: Status;
    parentTask?: Lookup;
    isDeleted?: boolean;

    constructor(obj: Partial<TaskContract>) {
        Object.assign(this, obj);
        if (!this.name) this.name = "";
    }
}

export class KanbanContract {
    id!: string;
    name!: string;
    number?: string;
    status?: { id: string; name?: string };

    constructor(obj: Partial<KanbanContract>) {
        Object.assign(this, obj);
        if (!this.name) this.name = "";
    }
}
