import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filter, GroupUser, Lookup } from 'processdelight-angular-components';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import {
  PROJECT_ISHTAR_PROJECT_ID,
  TASK_ISHTAR_PROJECT_ID,
} from '../data/constants';
import { InitialUserGroupContract } from '../domain/models/initial-load';
import {
  AvailableUser,
  ResourceFunction,
} from '../domain/models/resource/resource.model';
import { CheckListItem } from '../domain/models/task/checklistItem';
import { CreateTaskRequest } from '../domain/models/task/createTaskRequest';
import { GetTaskResponse } from '../domain/models/task/get-task-response';
import { KanbanContract, Task, TaskContract } from '../domain/models/task/task';
import { TaskFilterRequest } from '../domain/models/task/taskFilterRequest';
import { TaskLog } from '../domain/models/task/taskLog';
import { UpdateStatusTaskRequest } from '../domain/models/task/updateStatusTaskRequest';
import { ObjectUtils } from '../utils/object-utility';
import { SQLUtility } from '../utils/sql-utility';
import { FunctionsService } from './functions.service';
import { IshtarTimeRegistration } from '../domain/models/time/ishtarTimeRegistration';

@Injectable({ providedIn: 'root' })
export class TaskApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient,
    private functionsService: FunctionsService
  ) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////
  public canTaskStart(id: string): Observable<
    | {
        label: string;
        dependencyName: string;
      }
    | undefined
  > {
    const url = `${this.apiBase}/ishtartasks/${id}/canStart`;
    return this.httpClient
      .get<
        | {
            label: string;
            dependencyName: string;
          }
        | undefined
      >(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTaskTranslations(): Observable<{ [id: string]: string }> {
    const url = `${this.apiBase}/ishtartasks/translation`;

    return this.httpClient.get<{ [id: string]: string }>(url).pipe(
      catchError((error) => {
        console.error(
          'An error occurred on task translations retrieval:',
          error
        );
        return throwError(error);
      })
    );
  }

  public getTaskInitialUserGroups(): Observable<InitialUserGroupContract> {
    const url = `${this.apiBase}/ishtartasks/user-group`;

    return this.httpClient.get<InitialUserGroupContract>(url).pipe(
      catchError((error) => {
        console.error(
          'An error occurred on task users and groups retrieval:',
          error
        );
        return throwError(error);
      })
    );
  }

  public getTaskGroups(): Observable<GroupUser[]> {
    const url = `${this.apiBase}/ishtartasks/group`;

    return this.httpClient.get<GroupUser[]>(url).pipe(
      catchError((error) => {
        console.error('An error occurred on task groups retrieval:', error);
        return throwError(error);
      })
    );
  }

  public getAllTasks(): Observable<TaskContract[]> {
    const url = `${this.apiBase}/ishtartasks/all`;
    return this.httpClient.get<TaskContract[]>(url).pipe(
      map(
        (task) =>
          task?.map((t) => new TaskContract(ObjectUtils.camelcaseKeys(t))) || []
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getTasks(
    newPageSize: number,
    sortedColumn: string,
    sortDirection: string,
    assignedToMe: boolean,
    filters: [string, any][],
    newPageNumber?: number,
    initialLoading = false,
    resetCache = false
  ): Observable<GetTaskResponse> {
    const url = `${
      this.apiBase
    }/ishtartasks?doPaging=true&retrieveTotalRecordCount=true&assignedToMe=${assignedToMe}&top=${newPageSize}&orderBy=${SQLUtility.getTaskSQLColumnName(
      sortedColumn
    )}&direction=${sortDirection}${
      newPageNumber ? `&pageNumber=${newPageNumber}` : ''
    }${initialLoading ? `&initialLoading=${initialLoading}` : ''}
    ${resetCache ? `&resetCache=${resetCache}` : ''}`;

    const body: TaskFilterRequest = {
      sqlFilter: this.sqlFilterQuery(filters),
      filters: filters.map((f) => `${f[0]}=${f[1]}`).join('&'),
    };

    return this.httpClient.post<GetTaskResponse>(url, body).pipe(
      map((response) => ObjectUtils.camelcaseKeys(response)),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getTaskDetails(id: string): Observable<Task> {
    const url = `${this.apiBase}/ishtartasks/task-details/${id}`;
    return this.httpClient.get<Task>(url).pipe(
      map((task) => new Task(ObjectUtils.camelcaseKeys(task))),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getSubTasks(parentTaskId: string): Observable<Task[]> {
    const url = `${this.apiBase}/ishtartasks/subtasks/${parentTaskId}`;
    return this.httpClient.get<Task[]>(url).pipe(
      map((tasks) => tasks.map((t) => new Task(ObjectUtils.camelcaseKeys(t)))),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getTask(ishtarTaskId: string): Observable<Task> {
    const url = `${this.apiBase}/ishtartasks/${ishtarTaskId}/get-task?expand=Project,ParentTask&select=*,Project,ParentTask`;
    return this.httpClient.get<Task>(url).pipe(
      map((task) => new Task(ObjectUtils.camelcaseKeys(task))),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getCheckListItemsByTaskId(
    taskId: string
  ): Observable<CheckListItem[]> {
    const url = `${this.apiBase}/ishtartasks/checklist-items?expand=Task&select=*,Task&orderBy=Position ASC&filter=Task/IshtarTaskId eq ${taskId}`;
    if (!taskId) {
      return of([]);
    }
    return this.httpClient.get<CheckListItem[]>(url).pipe(
      map((checklistItem) =>
        checklistItem.map(
          (p) => new CheckListItem(ObjectUtils.camelcaseKeys(p))
        )
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getCheckListItems(): Observable<CheckListItem[]> {
    const url = `${this.apiBase}/ishtartasks/checklist-items`;
    return this.httpClient.get<CheckListItem[]>(url).pipe(
      map((checklistItem) =>
        checklistItem.map(
          (p) => new CheckListItem(ObjectUtils.camelcaseKeys(p))
        )
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getKanban(assignedToMe: boolean): Observable<KanbanContract[]> {
    const url = `${this.apiBase}/ishtartasks/kanban?assignedToMe=${assignedToMe}`;
    return this.httpClient.get<KanbanContract[]>(url).pipe(
      map(
        (task) =>
          task?.map((t) => new KanbanContract(ObjectUtils.camelcaseKeys(t))) ||
          []
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getLatestTaskNumber(): Observable<number> {
    const url = `${this.apiBase}/ishtartasks/getLatestTaskNumber`;
    return this.httpClient.get<number>(url).pipe(
      map((latestTaskNumber) => {
        return latestTaskNumber;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getTotalRegisteredTime(
    filters: Filter[]
  ): Observable<IshtarTimeRegistration[]> {
    const url = `${
      this.apiBase
    }/ishtartasks/total-registered-time?${this.functionsService.filterTimeRegistrationsQuery(
      filters
    )}`;
    return this.httpClient.get<IshtarTimeRegistration[]>(url).pipe(
      map((timeRegistrations) =>
        timeRegistrations.map(
          (tr) => new IshtarTimeRegistration(ObjectUtils.camelcaseKeys(tr))
        )
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getAvailableResources(
    startDate: string,
    endDate: string
  ): Observable<{
    resourceUsers: AvailableUser[];
    resourceFunctions: ResourceFunction[];
  }> {
    const url = `${this.apiBase}/ishtartasks/availableResources?startDate=${startDate}&endDate=${endDate}`;
    return this.httpClient
      .get<{
        resourceUsers: AvailableUser[];
        resourceFunctions: ResourceFunction[];
      }>(url)
      .pipe(
        map((data) => {
          return {
            resourceUsers: data.resourceUsers.map(
              (r) => new AvailableUser(ObjectUtils.camelcaseKeys(r))
            ),
            resourceFunctions: data.resourceFunctions.map(
              (r) => new ResourceFunction(ObjectUtils.camelcaseKeys(r))
            ),
          };
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public addTask(task: Task, createTaskChannel?: boolean): Observable<Task> {
    const url = `${this.apiBase}/ishtartasks/add`;
    const addedTask = ObjectUtils.capitalizeKeys(
      task,
      'Task',
      'TaskType',
      'Status'
    );

    const body: CreateTaskRequest = {
      addedTask: addedTask,
      createTaskChannel: createTaskChannel ?? false,
    };

    const capitalizedBody = ObjectUtils.capitalizeKeys(body, 'AddedTask');

    return this.httpClient.post<Task>(url, capitalizedBody).pipe(
      map((task) => new Task(ObjectUtils.camelcaseKeys(task))),
      catchError((error) => {
        console.error('An error occurred on creating task:', error);
        return throwError(error);
      })
    );
  }

  public addTaskLogs(taskLogs: TaskLog[]): Observable<TaskLog[]> {
    const url = `${this.apiBase}/ishtartasks/add-task-logs`;
    const addedTaskLogs = taskLogs.map((t) =>
      ObjectUtils.capitalizeKeys(t, 'Task', 'LogType')
    );
    return this.httpClient.post<TaskLog[]>(url, addedTaskLogs).pipe(
      map((taskLogs) =>
        taskLogs.map((t) => new TaskLog(ObjectUtils.camelcaseKeys(t)))
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public linkSubtasks(
    taskId: string,
    subtaskIds: string[]
  ): Observable<Task[]> {
    const url = `${this.apiBase}/ishtartasks/${taskId}/linkSubtasks`;
    return this.httpClient.post<Task[]>(url, subtaskIds).pipe(
      map((subtasks) =>
        subtasks.map((p) => new Task(ObjectUtils.camelcaseKeys(p)))
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  public updateTask(task: Task): Observable<Task> {
    const url = `${this.apiBase}/ishtartasks/${task.ishtarTaskId}/update`;
    const updatedTask = ObjectUtils.capitalizeKeys(
      task,
      'Task',
      'Type',
      'Status'
    );
    return this.httpClient.patch<Task>(url, updatedTask).pipe(
      map((task) => new Task(ObjectUtils.camelcaseKeys(task))),
      catchError((error) => {
        console.error('An error occurred on updating task:', error);
        return throwError(error);
      })
    );
  }

  public updateTaskStatus(taskId: string, statusId: string): Observable<Task> {
    const url = `${this.apiBase}/ishtartasks/${taskId}/update-status`;
    return this.httpClient
      .patch<[Task]>(url, [
        new UpdateStatusTaskRequest({
          ishtarTaskId: taskId,
          status: new Lookup({
            id: statusId,
          }),
        }),
      ])
      .pipe(
        map((task) => new Task(ObjectUtils.camelcaseKeys(task[0]))),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  // soft delete so set IsDeleted to true
  public removeTask(id: string): Observable<string> {
    const url = `${this.apiBase}/ishtartasks/${id}/remove`;
    return this.httpClient.delete<Task>(url).pipe(
      map((task) => (ObjectUtils.camelcaseKeys(task) as Task).ishtarTaskId),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  //////////////////////////////////////////////// Utils //////////////////////////////////////////////////
  private sqlFilterQuery(filters: [string, any][]): string {
    if (filters.length > 0) {
      const filterString = '';
      return (
        filterString +
        filters
          .map((filter) => {
            switch (filter[0]) {
              case PROJECT_ISHTAR_PROJECT_ID:
                return `${TASK_ISHTAR_PROJECT_ID} ='${filter[1]}'`;
              case 'StartTime':
                return this.functionsService.getDateFilter(
                  'ishtar_starttime',
                  filter[1]
                );
              case 'EndTime':
                return this.functionsService.getDateFilter(
                  'ishtar_endtime',
                  filter[1]
                );
              case 'Deadline':
                return this.functionsService.getDateFilter(
                  'ishtar_deadline',
                  filter[1]
                );
              default:
                return `${filter[0]} LIKE '%${filter[1]}%'`;
            }
          })
          .join(' and ')
      );
    } else {
      return '';
    }
  }
}
