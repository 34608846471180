import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getTasks,
  getTasksResolved,
  getTaskById,
  getTaskResolved,
  addTask,
  addTaskResolved,
  deleteTask,
  deleteTaskResolved,
  updateTask,
  updateTaskResolved,
  getAllTasks,
  getAllTasksResolved,
  createTaskDocuments,
  createTaskDocumentsResolved,
  addLogs,
  addLogsResolved,
  removeTaskDocuments,
  removeTaskDocumentsResolved,
  linkSubTasks,
  linkSubTasksResolved,
  updateTaskStatus,
  addStatuses,
  addStatusesResolved,
  updateStatuses,
  updateStatusesResolved,
  getKanban,
  getKanbanResolved,
  getTaskDocuments,
  getTaskDocumentsResolved,
  getSubTasks,
  getSubTasksResolved,
  deleteStatus,
  deleteStatusResolved,
  getStatuses,
  getStatusesResolved,
  getTemplateTasks,
  getTemplateTasksResolved,
} from './task.actions';
import { TaskApiService } from '../../services/task-api.service';
import { TaskDocumentsApiService } from '../../services/task-documents-api.service';
import { StatusApiService } from '../../services/status-api.service';
import { TypesApiService } from '../../services/types-api.service';

@Injectable({ providedIn: 'root' })
export class TaskEffects {
  filter = 'IsDeleted eq false';
  constructor(
    private actions$: Actions,
    private statusApiService: StatusApiService,
    private typeApiService: TypesApiService,
    private taskDocumentsApiService: TaskDocumentsApiService,
    private taskApiService: TaskApiService
  ) {}

  getTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(getTasks),
      mergeMap(({ assignedToMe, orderBy, direction, filters, pageSize, page, resetPaging, callback, errorCallback }) =>
        this.taskApiService.getTasks(assignedToMe, orderBy, direction, filters, pageSize, page)
          .pipe(
            switchMap(({ result, totalRecordCount }) =>
              of(getTasksResolved({
                  tasks: result,
                  pageNumber: page,
                  totalRecordCount,
                  resetPaging
                })
              )
            ),
            tap((response) => (callback ? callback(response.tasks) : undefined)),
            catchError((e) => {
              errorCallback?.(e);
              return [];
            })
          )
      )
    )
  );

  getAllTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllTasks),
      switchMap(({ callback, errorCallback }) =>
        this.taskApiService.getAllTasks().pipe(
          tap(() => (callback ? callback() : undefined)),
          switchMap((data) => of(getAllTasksResolved({ tasks: data }))),
          catchError((e) => {
            errorCallback?.(e);
            return [];
          })
        )
      )
    )
  );

  getTemplateTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(getTemplateTasks),
      switchMap(({ callback, errorCallback }) =>
        this.taskApiService.getTemplateTasks().pipe(
          tap(() => (callback ? callback() : undefined)),
          switchMap((data) => of(getTemplateTasksResolved({ tasks: data }))),
          catchError((e) => {
            errorCallback?.(e);
            return [];
          })
        )
      )
    )
  );

  getTaskById = createEffect(() =>
    this.actions$.pipe(
      ofType(getTaskById),
      mergeMap(({ id, errorCallback, callback }) =>
        this.taskApiService.getTaskById(id).pipe(
          tap((task) => (callback ? callback({ task }) : undefined)),
          switchMap((task) => of(getTaskResolved({ task }))),
          catchError((e) => {
            errorCallback?.(e);
            return [];
          })
        )
      )
    )
  );

  addTask = createEffect(() =>
    this.actions$.pipe(
      ofType(addTask),
      mergeMap(({ task, callback, errorCallback }) =>
        this.taskApiService.addTask(task).pipe(
          tap((task) => (callback ? callback(task) : undefined)),
          switchMap((addedTask) => of(addTaskResolved({ addedTask }))),
          catchError((e) => {
            errorCallback?.(e);
            return [];
          })
        )
      )
    )
  );

  // addSubtask = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(addSubtask),
  //     mergeMap(({ task }) =>
  //       this.ishtarFunctionService.addTask(task).pipe(
  //         switchMap((addedTask) => of(addTaskResolved({ addedTask }))),
  //         catchError((e) => [])
  //       )
  //     )
  //   )
  // );

  updateTask = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTask),
      mergeMap(({ task, callback, error }) =>
        this.taskApiService.updateTask(task).pipe(
          tap((task) => (callback ? callback(task) : undefined)),
          switchMap((task) => {
            return of(updateTaskResolved(task));
          }),
          catchError((e) => {
            error?.(e);
            return [];
          })
        )
      )
    )
  );

  updateTaskStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTaskStatus),
      mergeMap(({ taskId, statusId, callback, error }) =>
        this.taskApiService.updateTaskStatus(taskId, statusId).pipe(
          tap((tasks) => (callback ? callback({ tasks }) : undefined)),
          switchMap((tasks) => {
            return of(updateTaskResolved(tasks));
          }),
          catchError((e) => {
            error?.(e);
            return [];
          })
        )
      )
    )
  );

  removeTask = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTask),
      mergeMap(({ id, callback, errorCallback }) =>
        this.taskApiService.removeTask(id).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          mergeMap((data) => of(deleteTaskResolved({ id: data }))),
          catchError((e) => {
            if (errorCallback) errorCallback(e);
            return [];
          })
        )
      )
    )
  );

  addLogs = createEffect(() =>
    this.actions$.pipe(
      ofType(addLogs),
      mergeMap(({ logs, callback, error }) =>
        this.taskApiService.addLogs(logs).pipe(
          tap((logs) => (callback ? callback(logs) : undefined)),
          switchMap((logs) => of(addLogsResolved({ logs }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getTaskDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(getTaskDocuments),
      mergeMap(({ taskId, callback, errorCallback }) =>
        this.taskDocumentsApiService.getTaskDocuments(taskId).pipe(
          tap((taskDocuments) =>
            callback ? callback(taskDocuments) : undefined
          ),
          switchMap((taskDocuments) =>
            of(getTaskDocumentsResolved({ taskDocuments }))
          ),
          catchError((e) => {
            errorCallback?.(e);
            return [];
          })
        )
      )
    )
  );

  createTaskDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(createTaskDocuments),
      mergeMap(({ documents, callback, error }) =>
        this.taskDocumentsApiService.createTaskDocuments(documents)
          .pipe(
            tap((logs) => (callback ? callback(logs) : undefined)),
            switchMap((contract) =>
              of(
                createTaskDocumentsResolved({
                  contract: contract,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  removeTaskDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTaskDocuments),
      mergeMap(({ taskId, dmsAppItemIds, callback, error }) =>
        this.taskDocumentsApiService.removeTaskDocuments(dmsAppItemIds).pipe(
          tap((logs) => (callback ? callback(logs) : undefined)),
          switchMap((ids) =>
            of(
              removeTaskDocumentsResolved({
                taskId,
                dmsAppItemIds: ids,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getSubTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubTasks),
      mergeMap(({ parentTaskId, callback, errorCallback }) =>
        this.taskApiService.getSubTasks(parentTaskId).pipe(
          tap((subTasks) => (callback ? callback(subTasks) : undefined)),
          switchMap((subTasks) => of(getSubTasksResolved({ subTasks }))),
          catchError((e) => {
            errorCallback?.(e);
            return [];
          })
        )
      )
    )
  );

  linkSubTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(linkSubTasks),
      mergeMap(({ taskId, subTaskIds, callback }) =>
        this.taskApiService.linkSubTasks(taskId, subTaskIds).pipe(
          tap((tasks) =>
            callback ? callback({ subtasks: tasks }) : undefined
          ),
          switchMap((tasks) => of(linkSubTasksResolved({ subTasks: tasks }))),
          catchError((e) => [])
        )
      )
    )
  );

  addStatuses = createEffect(() =>
    this.actions$.pipe(
      ofType(addStatuses),
      switchMap(({ statuses, callback, error }) =>
        this.statusApiService.addStatusses(statuses).pipe(
          tap((statuses) => (callback ? callback(statuses) : undefined)),
          switchMap((addedStatuses) =>
            of(addStatusesResolved({ addedStatuses }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getStatuses = createEffect(() =>
    this.actions$.pipe(
      ofType(getStatuses),
      switchMap(({ callback, error }) =>
        this.statusApiService.getStatusses().pipe(
          tap((statuses) => (callback ? callback(statuses) : undefined)),
          switchMap((statuses) => of(getStatusesResolved({ statuses }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateStatuses = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStatuses),
      switchMap(({ statuses, callback, error }) =>
        this.statusApiService.updateStatusses(statuses).pipe(
          tap((statuses) => (callback ? callback(statuses) : undefined)),
          switchMap((updatedStatuses) =>
            of(updateStatusesResolved({ updatedStatuses }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteStatus),
      switchMap(({ id, callback, error }) =>
        this.statusApiService.removeStatus(id).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((data) => of(deleteStatusResolved({ id: data }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getkanban = createEffect(() =>
    this.actions$.pipe(
      ofType(getKanban),
      switchMap(({ assignedToMe, callback, error }) =>
        this.taskApiService.getKanban(assignedToMe).pipe(
          tap((x) => (callback ? callback({ tasks: x }) : undefined)),
          switchMap((tasks) => of(getKanbanResolved({ tasks }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
