import {
  GroupUser,
  Lookup,
  TriggerType,
} from 'processdelight-angular-components';
import { BehaviorSubject } from 'rxjs';
import { DMSMetadataParameter } from '../domain/models/dms/dms-item.model';
import { Day } from '../domain/models/task/batch/day';
import { Month } from '../domain/models/task/batch/month';
import { Ranking } from '../domain/models/task/batch/ranking';
import { ApprovalType } from '../domain/models/task/decision/approvalType';
import { DependencyType } from '../domain/models/task/dependencyType';
import { ProjectHolder } from '../domain/models/task/project';
import { RegistrationType } from '../domain/models/task/registrationType';
import { Skill } from '../domain/models/task/skill';
import { Task } from '../domain/models/task/task';
import { TaskLogType } from '../domain/models/task/taskLogType';
import { TaskType } from '../domain/models/task/taskType';
import { LicenseInfo } from '../domain/models/user/licenseinfo';
import { UserLicenseInfo } from '../domain/models/user/user-license-info.model';
import {
  ResourceThing,
  ResourceUser,
} from '../domain/models/resource/resource.model';
import { ActionType } from '../domain/models/task/decision/actionType';

export const search$ = new BehaviorSubject<string>('');

export const currentApplication$ = new BehaviorSubject<Lookup | undefined>(
  undefined
);
export const license$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);
export const tasksLicense$ = new BehaviorSubject<LicenseInfo | undefined>(
  undefined
);
export const users$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const groups$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const projects$ = new BehaviorSubject<ProjectHolder[] | undefined>(
  undefined
);
export const skills$ = new BehaviorSubject<Skill[] | undefined>(undefined);
export const taskTypes$ = new BehaviorSubject<TaskType[] | undefined>(
  undefined
);
export const registrationTypes$ = new BehaviorSubject<
  RegistrationType[] | undefined
>(undefined);
export const actionType$ = new BehaviorSubject<ActionType[] | undefined>(
  undefined
);
export const tasks$ = new BehaviorSubject<Task[] | undefined>(undefined);
export const dependencyTypes$ = new BehaviorSubject<
  DependencyType[] | undefined
>(undefined);
export const taskLogTypes$ = new BehaviorSubject<TaskLogType[] | undefined>(
  undefined
);
export const approvalTypes$ = new BehaviorSubject<ApprovalType[] | undefined>(
  undefined
);
export const notificationFrequencies$ = new BehaviorSubject<
  Lookup[] | undefined
>(undefined);
export const days$ = new BehaviorSubject<Day[] | undefined>(undefined);
export const months$ = new BehaviorSubject<Month[] | undefined>(undefined);
export const rankings$ = new BehaviorSubject<Ranking[] | undefined>(undefined);
export const triggerTypes$ = new BehaviorSubject<TriggerType[] | undefined>(
  undefined
);
export const channelTypes$ = new BehaviorSubject<Lookup[] | undefined>(
  undefined
);
export const metadataParameters$ = new BehaviorSubject<
  DMSMetadataParameter[] | undefined
>(undefined);
export const frequencies$ = new BehaviorSubject<Lookup[] | undefined>(
  undefined
);
export const resourceThings$ = new BehaviorSubject<ResourceThing[] | undefined>(
  undefined
);
export const resourceUsers$ = new BehaviorSubject<ResourceUser[] | undefined>(
  undefined
);
